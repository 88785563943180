import { ReactComponent as IconLinkedIn } from './assets/icons/linkedin.svg';
import "./App.css";

function App() {
  return (
    <div className="card">
    <div className="header">
      <div className="logo">
      </div>
      <div className="social">
        <a href="https://www.linkedin.com/in/gerald-suleh/" title="Linkedin" target="_blank" rel="noopener noreferrer">
          <IconLinkedIn className="icon" />
        </a>
      </div>
    </div>
    <div className="content">
      <div className="title-holder">
        <h1>Something awesome in the works!</h1>
        <p>Website coming soon. Please check back to know more. Shoot me a chat on Linkedin if you want to know more.</p>
      </div>
      <a href="https://www.linkedin.com/in/gerald-suleh/">
        <div className="cta">Let's connect!</div>
      </a>
    </div>
    <div className="footer">
      <span>made by <a className="underlined" href="https://github.com/GSuleh" target="_blank" rel="noopener noreferrer">Gerald Suleh</a> using <a className="underlined" href="https://reactjs.org/" title="ReactJS" target="_blank" rel="noopener noreferrer">React</a> | <a className="underlined" href="https://github.com/GSuleh" title="GitHub repo" target="_blank" rel="noopener noreferrer">GitHub</a></span>
    </div>
  </div>
);
}

export default App;
